@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	padding: 0;
	background-color: #000000;
}

/* Light Mode Styles */
.dark\:bg-white {
	background-color: white;
}

/* Dark Mode Styles */
.dark\:bg-gray-800 {
	background-color: #2c3e50;
}

@font-face {
	font-family: "GoodTime";
	src: url("./assets/fonts/good\ times.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "nasalization";
	src: url("./assets/fonts/nasalization.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 32px;
}

h3 {
	font-size: 28px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 18px;
}

@media (max-width: 992px) {
	h1 {
		font-size: 32px;
	}

	h2 {
		font-size: 28px;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 16px;
	}
}

@media (max-width: 768px) {
	h1 {
		font-size: 28px;
	}

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 20px;
	}

	h4 {
		font-size: 18px;
	}

	h5 {
		font-size: 14px;
	}
}

@media (max-width: 576px) {
	h1 {
		font-size: 24px;
	}

	h2 {
		font-size: 20px;
	}

	h3 {
		font-size: 18px;
	}

	h4 {
		font-size: 16px;
	}
}

@media (max-width: 350px) {
	h1 {
		font-size: 20px;
	}

	h2 {
		font-size: 18px;
	}

	h3 {
		font-size: 16px;
	}

	h4 {
		font-size: 14px;
	}
}

@media (max-width: 281px) {
	h1 {
		font-size: 18px;
	}

	h2 {
		font-size: 16px;
	}

	h3 {
		font-size: 14px;
	}
}

a {
	text-decoration: none;
}

p {
	font-size: 14px;
}

.fz-16 {
	font-size: 16px !important;
}

.fz-18 {
	font-size: 18px !important;
}

.fz-20 {
	font-size: 20px !important;
}

.fz-24 {
	font-size: 24px !important;
}

.fz-28 {
	font-size: 28px !important;
}

.fz-32 {
	font-size: 32px !important;
}

.fz-36 {
	font-size: 36px !important;
}

.fz-42 {
	font-size: 42px !important;
}

.fz-48 {
	font-size: 48px !important;
}

.fz-54 {
	font-size: 54px !important;
}

.fz-60 {
	font-size: 60px !important;
}

.fz-72 {
	font-size: 72px !important;
}

@media (max-width: 992px) {
	.fz-18 {
		font-size: 16px !important;
	}

	.fz-20 {
		font-size: 18px !important;
	}

	.fz-24 {
		font-size: 20px !important;
	}

	.fz-28 {
		font-size: 24px !important;
	}

	.fz-32 {
		font-size: 28px !important;
	}

	.fz-36 {
		font-size: 32px !important;
	}

	.fz-42 {
		font-size: 36px !important;
	}

	.fz-48 {
		font-size: 42px !important;
	}

	.fz-54 {
		font-size: 48px !important;
	}

	.fz-60 {
		font-size: 54px !important;
	}

	.fz-72 {
		font-size: 60px !important;
	}
}

@media (max-width: 768px) {
	.fz-16 {
		font-size: 14px !important;
	}

	.fz-18 {
		font-size: 16px !important;
	}

	.fz-20 {
		font-size: 16px !important;
	}

	.fz-24 {
		font-size: 18px !important;
	}

	.fz-28 {
		font-size: 20px !important;
	}

	.fz-32 {
		font-size: 24px !important;
	}

	.fz-36 {
		font-size: 28px !important;
	}

	.fz-42 {
		font-size: 32px !important;
	}

	.fz-48 {
		font-size: 36px !important;
	}

	.fz-54 {
		font-size: 42px !important;
	}

	.fz-60 {
		font-size: 48px !important;
	}

	.fz-72 {
		font-size: 54px !important;
	}
}

@media (max-width: 576px) {
	.fz-20 {
		font-size: 14px !important;
	}

	.fz-24 {
		font-size: 16px !important;
	}

	.fz-28 {
		font-size: 18px !important;
	}

	.fz-32 {
		font-size: 20px !important;
	}

	.fz-36 {
		font-size: 24px !important;
	}

	.fz-42 {
		font-size: 28px !important;
	}

	.fz-48 {
		font-size: 32px !important;
	}

	.fz-54 {
		font-size: 36px !important;
	}

	.fz-60 {
		font-size: 42px !important;
	}

	.fz-72 {
		font-size: 48px !important;
	}
}

@media (max-width: 350px) {
	.fz-24 {
		font-size: 14px !important;
	}

	.fz-28 {
		font-size: 16px !important;
	}

	.fz-32 {
		font-size: 18px !important;
	}

	.fz-36 {
		font-size: 20px !important;
	}

	.fz-42 {
		font-size: 24px !important;
	}

	.fz-48 {
		font-size: 28px !important;
	}

	.fz-54 {
		font-size: 32px !important;
	}

	.fz-60 {
		font-size: 36px !important;
	}

	.fz-72 {
		font-size: 42px !important;
	}
}

.main_container {
	width: 100vw;
	height: 100dvh;
}

.main_container_two {
	width: 100vw;
	height: 100%;
}

.content_container {
	max-width: 1440px;
	margin: 0 auto;
}

@media (max-width: 1520px) {
	.content_container {
		padding: 0 5vw;
	}
}

.swiper-pagination-bullet {
	background-color: #86858b !important;
	width: 45px !important;
	height: 6px !important;
	border-radius: 1px !important;
}

.featuresSwiper .swiper-pagination-bullet {
	background-color: white !important;
	width: 10px !important;
	height: 10px !important;
	border-radius: 100% !important;
}

.featuresSwiper .swiper-pagination-bullet-active {
	background-color: #d11c1c !important;
	width: 10px !important;
	height: 10px !important;
	border-radius: 100% !important;
}

.mainHero .swiper-pagination-bullet {
	width: 35px !important;
	height: 6px !important;
}

.mainHero .swiper-pagination-bullet-active {
	width: 45px !important;
	height: 6px !important;
}

@media screen and (max-width: 768px) {
	.mainHero .swiper-pagination-bullet {
		transform: rotate(90deg);
		margin-bottom: 5vw !important;
		width: 35px !important;
		height: 6px !important;
	}

	.mainHero .swiper-pagination-bullet-active {
		width: 45px !important;
		height: 6px !important;
	}
}

.swiper-pagination-bullet-active {
	background-color: #d11c1c !important;
	width: 45px !important;
	height: 6px !important;
	border-radius: 1px !important;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-vertical {
	display: flex !important;
	flex-direction: column !important;
	align-items: end !important;
	/* margin-right: 20px; */
}

/* Include this in your global CSS file */
/* Set up a CSS counter on the parent list */

/* .authSwiperPagination .swiper-pagination {
  position: absolute !important;
  top: 5% !important;
  left: -25% !important;
  height: fit-content;
  color: white;
} */

.authSwiperPagination .swiper-pagination-bullet-active {
	background-color: white !important;
	width: 45px !important;
	height: 6px !important;
	border-radius: 1px !important;
}

.custom-gradient-aboutUs {
	background: linear-gradient(180deg,
			#030303 0%,
			rgba(3, 3, 3, 0) 30.21%,
			rgba(3, 3, 3, 0) 77.6%,
			#a61818 100%),
		url("./assets/icons/about/enigmaPlatform.svg"), lightgray;
	background-size: cover;
	background-repeat: no-repeat;
}

/* Add this to your styles.css */
.red-underline {
	text-decoration-color: red;
	/* This sets the underline color to red */
}

.clipCarot {
	clip-path: polygon(100% 50%, 0 0, 0 100%);
}

.backdrop {
	position: fixed;
	/* Positioning relative to the viewport */
	top: 0;
	left: 0;
	width: 100vw;
	/* Full width */
	height: 100vh;
	/* Full height */
	background-color: rgba(0, 0, 0, 0.5);
	/* Semi-transparent background */
	z-index: 5;
	/* Make sure it's on top of other elements */
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	background: white;
	padding: 20px;
	border-radius: 5px;
	z-index: 6;
	/* Above the backdrop */
}

.active {
	text-decoration: underline;
	text-underline-offset: 22px;
	text-decoration-color: #d11c1c;
	/* or any other styling you want for the active tab */
}

@media (max-width: 768px) {
	.active {
		text-decoration: none;
	}
}

@media (max-width: 1520px) {
	.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-vertical {
		margin-right: 5vw !important;
	}
}

@media (max-width: 767px) {
	.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-vertical {
		margin-right: 0 !important;
		/* margin-top: 5vw !important; */
	}
}

.heroBottomSpan {
	/* color: transparent; */
	/* background: linear-gradient(to right, #232225, #232225); */
	/* background-clip: text; */
}

.heroBottomSpan:hover {
	/* background: radial-gradient(#d11c1c 0%,
			#232225); */
	/* Set the desired gradient on hover */
	/* transition: color 0.5s ease; */
	/* Set the transition duration and easing for the background */
	/* transition-delay: 0.1s; */
	color: '#d11c1c';
	/* Set the transition delay */
	/* background-clip: text; */
	/* cursor: default; */
}

.css-19hpjpw-control .css-1fdsijx-ValueContainer .css-aoxytf-singleValue h1 {
	font-size: 20px !important;
}

.css-1wrsnvo-control .css-1fdsijx-ValueContainer .css-aoxytf-singleValue h1 {
	font-size: 20px !important;
}

.css-yz1j3q-menu h1 {
	font-size: 20px !important;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hide-scrollbar {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.EZDrawer .EZDrawer__checkbox:checked~.EZDrawer__overlay {
	background-color: transparent !important;
}

@keyframes shrinkAndDisappear {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0);
		opacity: 0;
	}
}

.disappear {
	animation: shrinkAndDisappear 0.5s forwards;
}

.glow-effect {
	box-shadow: 0 0 8px rgba(255, 255, 255, 0.5),
		/* Inner white */
		0 0 12px rgba(255, 255, 255, 0.4),
		/* Middle white */
		0 0 16px rgba(255, 255, 255, 0.3);
	/* Outer white */
	border-radius: 9999px;
	/* Ensures the element is fully rounded */
}